





































































import Vue from "vue";
import ImageSelect from "@/components/base/ImageSelect.vue";

export default Vue.extend({
  name: "SelectTheme",

  props: ["theme"],

  components: {
    ImageSelect,
  },

  data() {
    return {
      dialog: false,
      imagesPaths: [],
      selectedTheme: this.theme,
      showTransitions: false,
      selectThemeKey: 0,
    };
  },

  mounted() {
    setTimeout(() => {
      this.showTransitions = true;
    }, 100);
  },

  watch: {
    theme() {
      this.selectedTheme = this.theme;
      this.setTheme(this.theme);
    },
  },

  created() {
    const imagePaths: any = require.context(
      "@/assets/theme",
      false,
      /^.*\.jpg$/
    );
    this.imagesPaths = imagePaths;
  },

  methods: {
    close() {
      this.selectThemeKey++;
      this.dialog = false;
      // on close select theme reset background to saved one
      this.selectedTheme = this.theme;
      this.setTheme(this.theme);
    },

    setTheme(theme: string) {
      const appElem: any = document.getElementById("application");
      appElem.style.cssText += `background-image: url(${theme}) !important`;
    },

    onSelectTheme(theme: any) {
      this.selectedTheme = theme;
      this.setTheme(theme);
    },

    onSaveTheme() {
      this.$emit("onSaveTheme", this.selectedTheme);
      this.dialog = false;
    },
  },
});
