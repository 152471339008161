import LanguageSpecificData from "./LanguageSpecificData";

export default class LanguageModulesDataInitializor {
  static getDefaultLanguageModulesData() {
    const languageJsonFilesPath: any = require.context(
      "@/locales",
      false,
      /^.*\.json$/
    );

    const languageModulesData: LanguageSpecificData[] = [];
    languageJsonFilesPath.keys().forEach((element: string) => {
      languageModulesData.push(
        Object.assign(new LanguageSpecificData(), {
          language: element.split(/.\/([^.]*).json/)[1],
          details: "",
          title: "",
        })
      );
    });
    return languageModulesData;
  }
}
