




























































































































































































































import Vue from "vue";
import IconSelect from "@/components/base/IconSelect.vue";
import ImageSelect from "@/components/base/ImageSelect.vue";
import ColorSelect from "@/components/base/ColorSelect.vue";
import Module from "@/models/moduleArea/Module";
import Country from "@/models/Country";
import LanguageGlobalSelectionModule from "@/store/modules/LanguageGlobalSelectionModule";
import { getModule } from "vuex-module-decorators";
import availableCountries from "@/data/countries";

export default Vue.extend({
  name: "ModuleDialog",

  props: ["editedItemId", "editedItem"],

  components: {
    IconSelect,
    ImageSelect,
    ColorSelect,
  },

  data() {
    return {
      dialog: false,
      form: new Module(),
      menuBackground: false,
      valid: true,
      imagesPaths: [],

      rules: {
        required: (value: string) => !!value || "Required field.",
      },

      countries: availableCountries,

      languageGlobalSelectionModule: getModule(
        LanguageGlobalSelectionModule,
        this.$store
      ),

      selectedCountry: -1,
      indexSelectedLanguage: -1,
      selectedCountryDetails: new Country("de"),
      showTransitions: false,
      moduleDialogKey: 0,
    };
  },

  created() {
    const imagePaths: any = require.context(
      "@/assets/module-images",
      false,
      /^.*\.png$/
    );
    this.imagesPaths = imagePaths;
    this.selectedCountry = this.countries.findIndex(
      (e) => e.locale === this.languageGlobalSelectionModule.selectedLanguage
    );
    this.selectedCountryDetails = this.countries[this.selectedCountry];
    this.setIndexSelectedLanguage();
  },

  mounted() {
    setTimeout(() => {
      this.showTransitions = true;
    }, 100);
  },

  destroyed() {
    this.resetForm();
  },

  watch: {
    selectedCountry() {
      const country = this.countries.find(
        (elem, index) => index == this.selectedCountry
      );
      this.selectedCountryDetails = country ? country : new Country();
      this.setIndexSelectedLanguage();
    },
  },

  methods: {
    close() {
      this.dialog = false;
      this.moduleDialogKey++;
      this.resetForm();
    },

    setItemDetails(val: Module) {
      this.form = Object.keys(val).length === 0 ? this.form : val;
      this.setIndexSelectedLanguage();
    },

    onSaveModuleDetails() {
      if (this.form.languageSpecificData.some((el) => el.title === "")) {
        alert("Please add a title for all available languages");
      } else {
        this.$emit("onSaveModuleDetails", this.form);
        this.close();
      }
    },

    resetForm() {
      const formElem: any = this.$refs.form;
      formElem?.resetValidation();
      this.form = new Module();
    },

    setIndexSelectedLanguage() {
      this.indexSelectedLanguage = this.form.languageSpecificData.findIndex(
        (element) => element.language === this.selectedCountryDetails.locale
      );
    },
  },
});
