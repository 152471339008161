import LanguageModulesDataInitializor from "./LanguageModulesDataInitializor";
import LanguageSpecificData from "./LanguageSpecificData";

export default class Module {
  languageSpecificData: LanguageSpecificData[] =
    LanguageModulesDataInitializor.getDefaultLanguageModulesData();
  link = "";
  asset = "image"; // icon | image
  image = "";
  icon = "mdi-heart";
  color = "#FFFFFF";
  background = "#24397F";
}
