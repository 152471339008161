export default class Country {
  locale = "";
  flag = "";
  name = "";

  constructor(flag?: string, locale?: string, name?: string) {
    this.flag = flag ? flag : "";
    this.locale = locale ? locale : "";
    this.name = name ? name : "";
  }
}
