














































import Vue from "vue";

export default Vue.extend({
  // This component describes a container to render all body content SpyderX-like.
  // You can include this component to your new page and use the slot, to render your content.
  // Example:
  // <ContentContainer title="MyHelloWorldPage">
  //     <h1>Hello World</h1>
  // </ContentContainer>

  name: "ContentContainer",

  // The following properties have to be passed from the parent component:
  // title (String): Used to show a title above the content.
  props: ["title", "description", "isHomepage"],

  data() {
    return {
      showTransition: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.showTransition = true;
    }, 100);
  },

  computed: {
    // Calculates the minimal height of the content. This way it will be stretched to the bottom footer,
    // if it does not contain enough content.
    minHeight(): any {
      const height = this.$vuetify.breakpoint.mdAndUp ? "100vh" : "50vh";
      const top = this.$vuetify.application.top;

      return `calc(${height} - ${top}px - 80px)`;
    },

    /**
     * @name containerClass
     * @description Use custom container class for homepage and fluid container for other pages
     */
    containerClass(): any {
      return {
        "custom-container": this.isHomepage,
        "container container--fluid": !this.isHomepage,
      };
    },
  },
});
